@import './../../styles/config.scss';

.loginPage {
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    /*width: 100%;
    background: $primary-color;

    .middleContainer {
        width: 90%;
        max-width: 400px;
        margin: 0px auto;
        padding: 20px;
        background: #fff;
        border-radius: 3px;
        box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;

        .header {
            margin-bottom: 10px;
        }

        .footer {
            margin-top: 20px;
        }
    }*/
    .formSide {
        width: 90%;
        max-width: 400px;
        margin: 0px auto;

        .container {
            width: 100%;
            background:#fff;
            padding: 40px 20px;
            border-radius: 3px;
            box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;;
        }
    }
}