@import './../../styles/config.scss';

.menubar {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;

    @media only screen and (max-width: 600px) {
        .title {
            display: none;
        }
    }

    .firstLayer, .secondLayer {
        width: 100%;

        .container {
            width: 90%;
            margin: 0px auto;
            max-width: $container-max-width;
        }
    }

    .firstLayer {
        padding: 0px;
        background: $primary-color;
        color: $primary-color-inverse;

        .personaButton {
            height: 65px;
            background: transparent;
            border: 0px;
            border-radius: 0;

            &:hover {
                background: #374575;
            }
        }
    }

    .secondLayer {
        padding-top: 10px;
        background: $primary-color-inverse;
        color: $primary-color;
    }

    .menuItem {
        position: relative;
        margin-right: 20px;

        .activeMenu::after {
            content: "";
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0px;
            height: 3px;
            border-top-right-radius: 3px;
            border-top-left-radius: 3px;
            background: $primary-color;
        }
    }
}