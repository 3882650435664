@import './config.scss';

.kepinternal {
    
}

.form {
    & > div {
        margin: 20px 0px;
    }
}

.commandBar {
    padding: 0px;

    .searchBox {
        min-width: 250px;
        height: 40px;
        margin: 2px 0px;
    }
}

.listActionButtons {
    padding: 5px;
}

.errorText {
    color: $error-text;
}

.warningText {
    color: $warning-text;
}