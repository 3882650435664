@import './../../styles/config.scss';

.home {
    .contentContainer {
        margin: 20px auto;
        width: 90%;
        max-width: 1040px;
        border-radius: 3px;
        box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
        max-width: $container-max-width;
    }

    .dashboardContainer {
        margin: 20px auto;
        width: 90%;
        max-width: 1040px;
        max-width: $container-max-width;
    }
}