.container {
    padding: 20px;

    .table {
        border: 1px solid #aaa;
        border-collapse: collapse;

        .title {
            border: 1px solid #aaa;
            padding: 8px;
            font-weight: 600;
            text-align: left;
            background-color: #c5bd98;
        }

        .text {
            border: 1px solid #aaa;
            padding: 8px;
        }
    }
}